import styled, {css} from 'styled-components';
import {device} from '../../../theme'

export const HeroWrapper = styled.section `
  position: relative;
  .hero-bg-image {

  }
  .hero-bg-image, .hero-bg-image:before, .hero-bg-image:after {
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    
  }
`;

export const HeroTextBox = styled.div `
    max-width: 730px;
    width: 100%;
    padding-left: 30px;
    margin-left: auto;
    color: white;
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
    z-index: 10;
    @media ${device.small}{
        padding-left: 0;
        margin-top: 50px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    @media ${device.xlargeOnly}{
      padding-top: 200px;
    padding-bottom: 160px;
  }
`;

export const Overlay = styled.div `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${css `
    background-color: ${props => props.theme.colors.textColor};
    `}
    opacity: 0.5;
`;
