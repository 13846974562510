import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import Hero from '../containers/index-infotechno/hero'
import ServiceArea from '../containers/index-processing/about-area'
import FeaturesArea from '../containers/index-infotechno/features-area'
import AboutArea from '../containers/index-infotechno/about-area'
import AboutServiceWrap from '../containers/index-infotechno/about-service-wrap'
import GradationArea from '../containers/index-services/gradation-area'
import VideoArea from '../containers/index-infotechno/video-area'
import CaseStudyArea from '../containers/index-infotechno/case-study-area/featured'
import TestimonialArea from '../containers/global/testimonial-area/section-two'
import ContactArea from '../containers/global/contact-area/contact-four'
import MailchimSignup from '../components/mailchim-signup'

const IndexInfoTechno = ({ location }) => {
    return (
        <Layout location={location}>
            <SEO title="Home" />
            <Header />
            <main className="site-wrapper-reveal">
                <Hero />
                <ServiceArea />
                <FeaturesArea />
                <AboutServiceWrap>
                    <AboutArea /> 
                </AboutServiceWrap>
                <GradationArea />
                <VideoArea />
                <CaseStudyArea />
                <TestimonialArea />
                <ContactArea />
            </main>
            <MailchimSignup />
            <Footer />
        </Layout>
    )
}

export default IndexInfoTechno; 