import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import parse from 'html-react-parser'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button';
import { HeroTextBox, HeroWrapper, Overlay } from './hero-area.style'

const HeroArea = (props) => {
    const heroDataQuery = useStaticQuery(graphql`
        query heroQueryData {
            indexInfotechnoJson(id: {eq: "infotechno-hero-content"}) {
                title
                subtitle
                shortDesc
                path
                image2 {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }

                    }
                }
            }
        }
    `);
    const { title, subtitle, shortDesc, path, image2 } = heroDataQuery.indexInfotechnoJson;
    const { subtitleStyle, titleStyle, btnStyle, textStyle } = props;

    return (
      <HeroWrapper>
          <BackgroundImage 
            tag="div"
            className="hero-bg-image"
            fluid={image2.childImageSharp.fluid}
          >
              <Container fluid>
                  <Row alignitems="center">
                      <Col md={7}>
                          <HeroTextBox>
                              {subtitle && <Heading {...subtitleStyle}>{subtitle}</Heading>}
                              {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                              {shortDesc && <Text {...textStyle}>{shortDesc}</Text>}
                              {path && <Button {...btnStyle} to={path}>Book a session today</Button>}
                          </HeroTextBox>
                      </Col>
                  </Row>
              </Container>
              <Overlay />
          </BackgroundImage>
      </HeroWrapper>
    )
}

HeroArea.propTypes = {
    subtitleStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    btnStyle: PropTypes.object
}

HeroArea.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        color: 'white',
        fontweight: 500,
        mb: '20px'
    },
    titleStyle: {
        as: 'h1',
        mb: '15px',
        color: 'white',
        fontweight: 500,
    },
    textStyle: {
      color: 'white',
      fontSize: '18px'
  },
    btnStyle: {
        hover: '2',
        mt: '15px'
    }
}

export default HeroArea;