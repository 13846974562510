import React, {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image';

import './style.scss'  


// Get mobile touch point
const getTouches = (evt) => {
  return evt.touches || // browser API
    evt.originalEvent.touches; // jQuery
}                                                  

const BeforeAfter = ({before, after, type}) => {

  // Setup DOM refrences to target later
  const scrollerRef = useRef(null)
  const wrapperRef = useRef(null)
  const afterRef = useRef(null)

  useEffect(() => {
    // Check DOM elements exist on this render
    if (!scrollerRef && !afterRef && !wrapperRef) return
    
    // Get the actual DOM reference
    let srcollBar = scrollerRef.current
    let wrapper = wrapperRef.current
    let after = afterRef.current    
    
    // Handlers for touch and mouse move
    const handleTouchMove = (evt) => {
      const firstTouch = getTouches(evt)[0];                                      
      if ( ! firstTouch.clientX) return
      if (!active) return;
    
      var x = evt.touches[0].clientX;                                    
                                                                             
      x -= wrapper.getBoundingClientRect().left;
      // Okay let's change our state
      scrollIt(x);                   
    };

    const handleMouseMove = (e) => {
      if (!active) return;
      // Their mouse is here...
      let x = e.pageX;
      // but we want it relative to our wrapper
      x -= wrapper.getBoundingClientRect().left;
      // Okay let's change our state
      scrollIt(x);
    }

    // Function to controll the slider bar
    function scrollIt(x){
      let transform = Math.max(0,(Math.min(x, wrapper.offsetWidth)));
      after.style.width = transform+"px";
      srcollBar.style.left = transform-25+"px";
    }

    // Let's use the 'active' variable to let us know when we're using it
    let active = false;

    // First we'll have to set up our event listeners
    // We want to watch for clicks on our scroller
    srcollBar.addEventListener('mousedown',function(){
      active = true;
      // Add our scrolling class so the scroller has full opacity while active
      srcollBar.classList.add('before-after__scrolling');
    });
    // We also want to watch the body for changes to the state,
    // like moving around and releasing the click
    // so let's set up our event listeners
    document.body.addEventListener('mouseup',function(){
      active = false;
      srcollBar.classList.remove('before-after__scrolling');
    });
    document.body.addEventListener('mouseleave',function(){
      active = false;
      srcollBar.classList.remove('before-after__scrolling');
    });
  
    document.addEventListener('touchmove', handleTouchMove, false);
    // Let's figure out where their mouse is at
    document.body.addEventListener('mousemove',handleMouseMove, false);
  
    // Let's set our opening state based off the width, 
    // we want to show a bit of both images so the user can see what's going on
    scrollIt(150);
  
    // And finally let's repeat the process for touch events
    // first our middle scroller...
    srcollBar.addEventListener('touchstart',function(e){
      active = true;
      srcollBar.classList.add('before-after__scrolling');
    });
    document.body.addEventListener('touchend',function(){
      active = false;
      srcollBar.classList.remove('before-after__scrolling');
    });
    document.body.addEventListener('touchcancel',function(){
      active = false;
      srcollBar.classList.remove('before-after__scrolling');
    });

  }, [scrollerRef, wrapperRef, afterRef])

  return (
    <div>
    <div ref={wrapperRef} className={`before-after__wrapper ${type === 'vertical' ? 'before-after__wrapper--vertical' : 'before-after__wrapper--horizontal' }`}>
      <div className="before-after__before">
        <Img className="before-after__content-image" fixed={before.fluid} draggable={false} />
      </div>
      <div ref={afterRef} className="before-after__after">
        <Img className="before-after__content-image" fixed={after.fluid} draggable={false}/>
      </div>
      <div ref={scrollerRef} className="before-after__scroller">
        <svg className="before-after__scroller-thumb" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><polygon points="0 50 37 68 37 32 0 50" style={{fill: '#fff'}}/><polygon points="100 50 64 32 64 68 100 50" style={{fill: '#fff'}}/></svg>
      </div>
    </div>
    </div>
  )
}
 
BeforeAfter.propTypes = {
  before: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  after: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  type: PropTypes.string,
} 

export default BeforeAfter