import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import { SectionWrap, LeftBox, Image } from './about-area.style'


const AboutArea = (props) => {
    const aboutDataQuery = useStaticQuery(graphql`
        query infotechnoAboutQueryData {
            indexInfotechnoJson(id: {eq: "infotechno-about-content"}) {
                title
                subtitle
                desc
                path
                image {
                  childImageSharp {
                    fluid(base64Width: 470, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                 }
            }
        }
    `);
    const { title, subtitle, path, desc, image } = aboutDataQuery.indexInfotechnoJson;
    const { sectionTitleStyle, descStyle, btnStyle, containerStyle } = props
    return (
        <SectionWrap>
            <Container>
                <Row style={{alignItems: 'center'}}>
                    <Col lg={5}>
                        <LeftBox>
                            <SectionTitle
                                {...sectionTitleStyle}
                                title={title}
                                subtitle={subtitle}
                            />
                            {desc && <Text {...descStyle}>{desc}</Text>}
                            {path && <Button to={path} {...btnStyle}>Join Us Now</Button>}
                        </LeftBox>
                    </Col>
                    <Col lg={5} ml="auto">
                      <Image>
                        <Img fluid={image.childImageSharp.fluid} />
                       </Image>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

AboutArea.propTypes = {
    descStyle: PropTypes.object,
    btnStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    containerStyle:  PropTypes.object,
}

AboutArea.defaultProps = {
    containerStyle: {
      alignItems: 'center'
    },
    sectionTitleStyle: {
        mb: '20px',
        align: 'left'
    },
    descStyle: {
        mb: '30px'
    },
    btnStyle: {
        hover: "2"
    }
}

export default AboutArea;