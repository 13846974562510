import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Text from '../../../components/ui/text'
import Anchor from '../../../components/ui/anchor'
import CaseStudyBox from '../../../components/box-large-image/layout-two'
import { SectionWrap } from './case-study-area.style'
import './style.scss'

const CaseStudyArea = ({
    sectionTitleStyle,
    rowWrap,
    bottomTextStyle,
    bottoTextLinkStyle,
    caseStudyStyles,
    }) => {
    const caseStudyData = useStaticQuery(graphql`
    query featured {
        indexInfotechnoJson(id: {eq: "infotechno-case-study-content"}) {
            id
            title
            subtitle
            bottom_text
            bottom_link_text
            bottom_link
            bgImage {
                childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
        }
        allCaseStudiesJson(filter: {is_featured: {eq: true}}, limit: 3) {
            edges {
              node {
                fields{
                    slug
                }
                id
                title
                category
                excerpt
                image {
                  childImageSharp {
                    fluid(maxWidth: 480, maxHeight: 298, quality: 100){
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        presentationWidth
                        presentationHeight
                    }
                  }
                }
                before {
                  childImageSharp {
                    fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
                after {
                  childImageSharp {
                    fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageType
              }
            }
        }
      } 
    `);
    const caseStudySecData = caseStudyData.indexInfotechnoJson;
    const caseStudies = caseStudyData.allCaseStudiesJson.edges;
    const imageData = caseStudySecData.bgImage.childImageSharp.fluid;

    return (
        <SectionWrap fluid={imageData}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={caseStudySecData.title}
                            subtitle={caseStudySecData.subtitle}
                        />
                    </Col>
                </Row>
                <Row {...rowWrap} className="gutter-xl-70 portfolio-wrapper">
                    {caseStudies.map(caseStudy => (
                        <CaseStudyBox
                          key={caseStudy.node.fields.slug}
                          {...caseStudyStyles}
                          before={caseStudy.node.before.childImageSharp}
                          after={caseStudy.node.after.childImageSharp}
                          type={caseStudy.node.imageType}
                          title={caseStudy.node.title}                                  
                          path={`/work`}
                        />
                    ))}
                </Row>
                <Row>
                    <Col lg={12}>
                        <Text {...bottomTextStyle}>
                            {caseStudySecData.bottom_text}
                            <Anchor {...bottoTextLinkStyle} path={caseStudySecData.bottom_link}>{caseStudySecData.bottom_link_text}</Anchor>
                        </Text>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

CaseStudyArea.propTypes = {
    sectionStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    bottomTextStyle: PropTypes.object,
    bottoTextLinkStyle: PropTypes.object
}

CaseStudyArea.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '27px'
            }
        }
    },
    bottomTextStyle: {
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        align: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    bottoTextLinkStyle: {
        fontWeight: 500,
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    slider: {
        slidesPerView: 3,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }
        }
    },
    sliderStyle: {
        pagination: {
            bottom: 0
        }
    },
    caseStudyStyles: {
        boxStyle: {
            mt: '20px',
            mb: '35px',
            ml: '15px',
            mr: '15px'
        },
        contentBoxStyle: {
            pt: '25px',
            pl: '26px',
            pr: '26px',
            textalign: 'left'
        },
        headingStyle: {
            as: 'h6',
            fontweight: 600,
            mb: '2px'
        },
        descStyle: {
            mb: 0,
            mt: '13px'
        }
    }
}

export default CaseStudyArea;