import React from 'react';
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Image from '../../image'
import {
    BoxLargeImgWrap, 
    BoxLargeImgInner, 
    BoxLargeImgMedia,
    BoxLargeImgLink,
    BoxLargeImgContent,
    HeadingWrap,
    CategoryWrap,
    TextWrap,
} from './box-large-image.style'
import BeforeAfter from '../../before-after';

const BoxLargeImage = ({before, after, title, type, category, desc, path, btnText, ...boxStyles}) => {
    const {boxStyle, contentBoxStyle, headingStyle, descStyle, btnStyle} = boxStyles;
   
    return (
        <BoxLargeImgWrap {...boxStyle}>
            <BoxLargeImgInner>
                 {(before && after) && (
                    <BeforeAfter
                      before={before}
                      after={after}
                      type={type}
                    />
                )}
                <BoxLargeImgContent {...contentBoxStyle}>
                    {title && <HeadingWrap as="h5" {...headingStyle}>{title}</HeadingWrap>}
                    {category && <CategoryWrap>{category}</CategoryWrap>}
                    {desc && <TextWrap {...descStyle}>{desc}</TextWrap>}
                </BoxLargeImgContent>
            </BoxLargeImgInner>
        </BoxLargeImgWrap>
    )
} 

BoxLargeImage.propTypes = {
    title: PropTypes.string,
    imageSrc: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    before: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    after: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    type: PropTypes.string,
    desc: PropTypes.string,
    path: PropTypes.string,
    btnText: PropTypes.string,
    headingStyle: PropTypes.object,
    descStyle: PropTypes.object,
    btnStyle: PropTypes.object
}

BoxLargeImage.defaultProps={
    path: '/',
    btnText: 'Discover Now',
    btnStyle: {
        iconposition: "right",
        icondistance: "4px",
        varient: "texted",
        hover: "false",
        display: 'flex',
        justify: 'center',
        alignitems: 'center',
        height: '100%'
    }
}


export default BoxLargeImage;
